/**
 * 共通JS
 * サイドバーなど
 */

import { throttle, getCookie, deleteCookie } from './library';

export class Common {
    constructor() {
        //空の ontouchstart 属性を body 要素に設定
        document.getElementsByTagName('body')[0].setAttribute('ontouchstart', '');

        /**
         * SP ハンバーガーメニュー
         */
        this.toggleEls = document.querySelectorAll('.toggle');
        this.fragmentIdEls = document.querySelectorAll('a[href*="#"]');
        this.overlayEls = document.querySelectorAll('.overlay');

        if( this.toggleEls ){
            this.toggleEls.forEach(el => el.addEventListener('click', () => {
                this.toggleSidebar(el, 'open');
            }));
        }
        if( this.fragmentIdEls ){
            this.fragmentIdEls.forEach(el => el.addEventListener('click', () => {
                this.toggleSidebar(el, 'close');
            }));
        }
        if( this.overlayEls ){
            this.overlayEls.forEach(el => el.addEventListener('click', () => {
                this.toggleSidebar(el, 'close');
            }));
        }

        // スムーススクロール
        this.scrollLinkEls = document.querySelectorAll('a[href^="#"]');
        if( this.scrollLinkEls.length > 0 ){
            this.scrollLinkEls.forEach(el => el.addEventListener('click', ev => this.smoothScroll(el, ev)));
        }

        /**
         * タイトルクリックで本文表示・非表示
         */
        this.toggleTitleEls = document.querySelectorAll('.toggle_ttl');
        this.toggleTitleEls.forEach(el => el.addEventListener('click', ev => this.toggleTitle(el, ev)));

        // 次の要素を発火
        this.fireNextEls = Array.from(document.getElementsByClassName('fire-next'));
        this.fireNextEls.forEach(el => el.addEventListener('click', ev => this.fireNext(el, ev)));

        // モーダル
        // 1. モーダル開く
        this.modalOpenBtnEls = Array.from(document.getElementsByClassName('modal-open'));
        this.modalOpenBtnEls.forEach(el => el.addEventListener('click', ev => this.openModal(el)));
        this.modalOpenWrapEls = Array.from(document.getElementsByClassName('modal-wrap'));
        this.modalOpenWrapEls.forEach(el => el.addEventListener('click', ev => this.openModal(ev.target)));
        // 2. モーダル閉じる
        this.modalCloseBtnEls = document.querySelectorAll('.modal-close, .modal-cancel');
        this.modalCloseBtnEls.forEach(el => el.addEventListener('click', ev => this.closeModal(el)));
        this.modalCloseWrapEls = Array.from(document.getElementsByClassName('modal-wrap'));
        this.modalCloseWrapEls.forEach(el => el.addEventListener('click', ev => this.closeModal(ev.target)));
        // 3. モーダルの外クリックでモーダル閉じる
        if( document.querySelector('.modal-container') ){
            document.addEventListener('click', ev => {
                if( ev.target.closest('.modal-container') && ev.target.closest('.modal-container').classList.contains('active') ){
                    if( !ev.target.closest('.modal-body') ){
                        this.closeModal(ev.target);
                    }
                }
            });
        }

        // notify-for-js
        this.notifyCloseEls = Array.from(document.getElementsByClassName('notify-close'));
        this.notifyCloseEls.forEach(el => el.addEventListener('click', () => this.closeNotify(el)));
    
        // 伸縮するテキストエリア
        this.flexTextarea();
    }

    /**
     * SP　サイドバー
     */
    toggleSidebar(el, type) {
        if( type == 'open' ){
            el.classList.toggle('active');
            document.querySelector('.toggle-menu').classList.toggle('show');
            document.body.classList.toggle('fixed');
            document.querySelector('.overlay').classList.toggle('active');
        }else{
            document.querySelector('.toggle').classList.remove('active');
            document.querySelector('.toggle-menu').classList.remove('show');
            document.body.classList.remove('fixed');
            document.querySelector('.overlay').classList.remove('active');
        }
    }

    /**
     * #のついたリンク先へスムーススクロールする
     */
    smoothScroll(el, ev) {
        ev.preventDefault();

        const adjust = -30;

        const href = el.getAttribute('href');
        let position;
        if( href == '#' || href == '' ){
            position = 0;
        }else{
            const target = document.getElementById(href.replace('#', ''));
            if( target ){
                position = document.getElementById(href.replace('#', '')).offsetTop;
            }else{
                position = 0;
            }
        }
        window.scrollTo({
            top: position + adjust,
            behavior: 'smooth'
        });
    }

    /**
     * タイトルクリックで本文表示・非表示
     */
    toggleTitle(el, ev) {
        $(el.nextElementSibling).slideToggle();
        el.nextElementSibling.classList.toggle('show');
        el.classList.toggle('active');
    }

    /**
     * 次の要素を発火
     */
    fireNext(el, ev) {
        ev.preventDefault();
        el.nextElementSibling.click();
    }

    /**
     * モーダルを開く
     */
    openModal(el) {
        const el1 = el.closest('.modal-open');
        const el2 = el.closest('.modal-wrap-open');
        el = [el1, el2].find(_el => _el != null);

        if( el ){
            const containerEls = document.getElementById(el.dataset.target);
            containerEls.classList.add('active');
        }
    }

    /**
     * モーダルを閉じる
     */
    closeModal(el) {
        const containerEls = el.closest('.modal-container');
        if( containerEls ){
            containerEls.classList.remove('active');
        }
    }

    /**
     * notify
     */
    closeNotify(el) {
        const notifyEl = el.closest('.notification');
        notifyEl.style.display = 'none';
    }

    /**
     * 伸縮するテキストエリア
     */
    flexTextarea() {
        document.querySelectorAll('.flex-textarea').forEach(el => {
            const dummy = el.querySelector('.flex-textarea-dummy');
            const textarea = el.querySelector('.flex-textarea-textarea');
            // 初期設定
            dummy.textContent = textarea.value + '\u200b'

            // 文字が入力されたとき
            textarea.addEventListener('input', e => {
                dummy.textContent = e.target.value + '\u200b'
            })
        });
    }
}

window.Common = new Common();
